<template>
  <div class="settingView">
    <van-nav-bar :title="$t('个人信息')" left-arrow @click-left="onClickLeft" />
    <van-cell :title="$t('头像')" @click="showHead = true" is-link>
      <template #icon>
        <span class="icon">
          <img src="@/assets/images/info_001.png" />
        </span>
      </template>
      <template #default>
        <img :src="avatar" height="40" />
      </template>
    </van-cell>
    <van-cell :title="$t('手机号')" :value="$store.getters.userinfo.mobile">
      <template #icon>
        <span class="icon">
          <img src="@/assets/images/info_002.png" />
        </span>
      </template>
    </van-cell>
    <!-- <van-cell :title="$t('银行卡')" @click="toCollections" :value="$t('点击设置')" is-link>
      <template #icon>
        <span class="icon">
          <img src="@/assets/images/info_003.png" />
        </span>
      </template>
    </van-cell> -->
    <van-cell :title="$t('详细信息')" to="/main/user/userinfo" :value="$t('点击设置')" is-link>
      <template #icon>
        <span class="icon">
          <img src="@/assets/images/info_005.png" />
        </span>
      </template>
    </van-cell>
    <van-cell :title="$t('登录密码')" @click="showPassword = true" :value="$t('点击设置')" is-link>
      <template #icon>
        <span class="icon">
          <img src="@/assets/images/info_006.png" />
        </span>
      </template>
    </van-cell>
    <van-cell :title="$t('资金密码')" @click="showRePaypwd = true" :value="$t('点击设置')" v-if="is_set_paypwd" is-link>
      <template #icon>
        <span class="icon">
          <img src="@/assets/images/info_007.png" />
        </span>
      </template>
    </van-cell>
    <van-cell :title="$t('资金密码')" @click="showSetPaypwd = true" :value="$t('点击设置')" v-if="!is_set_paypwd" is-link>
      <template #icon>
        <span class="icon">
          <img src="@/assets/images/info_007.png" />
        </span>
      </template>
    </van-cell>
    <van-cell :title="$t('清空缓存')" is-link @click="onClear" icon="delete" />

    <van-popup v-model="showHead" class="showHead" position="bottom" :style="{ height: '37%' }">
      <van-nav-bar :title="$t('修改头像')">
        <template #right>
          <van-icon name="cross" size="22" @click="hideHead" />
        </template>
      </van-nav-bar>
      <van-grid :gutter="10" :column-num="5">
        <van-grid-item v-for="value in 10" :key="value" @click="onAvatar(value)">
          <div class="icon">
            <img :src="require('@/assets/images/head_' + value + '.png')" />
          </div>
        </van-grid-item>
      </van-grid>
    </van-popup>
    <van-popup v-model="showPassword" class="showPassword" position="bottom" :style="{ height: '45%' }">
      <van-nav-bar :title="$t('修改登录密码')">
        <template #right>
          <van-icon name="cross" size="22" @click="hidePassword" />
        </template>
      </van-nav-bar>
      <van-cell-group>
        <van-field :label="$t('原登录密码')" type="password" v-model="oldpassword" :placeholder="$t('请输入登录密码')" />
        <van-field :label="$t('新登录密码')" type="password" v-model="password" :placeholder="$t('请输入登录密码')" />
        <van-field :label="$t('确认密码')" type="password" v-model="repassword" :placeholder="$t('请确认登录密码')" />
      </van-cell-group>
      <div class="btn">
        <van-button color="linear-gradient(90deg,#4b34c3,#3d3ff7)" @click="resetPassWord" block>{{ $t('提交') }}</van-button>
      </div>
    </van-popup>
    <van-popup v-model="showSetPaypwd" class="showPassword" position="bottom" :style="{ height: '37%' }">
      <van-nav-bar :title="$t('设置资金密码')">
        <template #right>
          <van-icon name="cross" size="22" @click="hideSetPaypwd" />
        </template>
      </van-nav-bar>
      <van-cell-group>
        <van-field :label="$t('资金密码')" type="password" v-model="paypwd" :placeholder="$t('请输入资金密码')" />
        <van-field :label="$t('确认资金密码')" type="password" v-model="repaypwd" :placeholder="$t('请确认资金密码')" />
      </van-cell-group>
      <div class="btn">
        <van-button color="linear-gradient(90deg,#4b34c3,#3d3ff7)" @click="setPayPwdHandle" block>{{ $t('提交') }}</van-button>
      </div>
    </van-popup>
    <van-popup v-model="showRePaypwd" class="showPassword" position="bottom" :style="{ height: '40%' }">
      <van-nav-bar :title="$t('修改资金密码')">
        <template #right>
          <van-icon name="cross" size="22" @click="hideRePaypwd" />
        </template>
      </van-nav-bar>
      <van-cell-group>
        <van-field :label="$t('原资金密码')" type="password" v-model="oldpaypwd" :placeholder="$t('请输入原资金密码')" />
        <van-field :label="$t('新资金密码')" type="password" v-model="paypwd" :placeholder="$t('请输入新资金密码')" />
        <van-field :label="$t('确认资金密码')" type="password" v-model="repaypwd" :placeholder="$t('请确认资金密码')" />
      </van-cell-group>
      <div class="btn">
        <van-button color="linear-gradient(90deg,#4b34c3,#3d3ff7)" @click="resetPaypwd" block>{{ $t('提交') }}</van-button>
      </div>
    </van-popup>
  </div>
</template>
<script>
import Vue from 'vue';
import { saveToLocal } from '@/utils/local-storage';
import { logout, setAvatar, rePassword, rePaypwd, setPaypwd } from '@/api/users';
import { setTimeout } from 'timers';
export default {
  name: 'Setting',
  data() {
    return {
      avatar: require('@/assets/images/head_' + this.$store.getters.userinfo.avatar + '.png'),
      autoChecked: this.$store.getters.is_auto_buy == 1 ? true : false,
      showLogOut: false,
      is_auto_buy: this.$store.getters.is_auto_buy,
      is_want_active: this.$store.getters.is_want_active,
      showHead: false,
      showPassword: false,
      showSetPaypwd: false,
      showRePaypwd: false,
      oldpassword: '',
      password: '',
      repassword: '',
      oldpaypwd: '',
      paypwd: '',
      repaypwd: '',
      is_set_paypwd: this.$store.getters.userinfo.is_set_paypwd,
    };
  },
  created() {},
  methods: {
    toCollections() {
      if (this.$store.getters.userinfo.realname) {
        this.$router.push('/main/user/collections');
      } else {
        this.$toast({
          message: this.$t('请先设置真实姓名'),
          duration: 1000,
          forbidClick: true,
        });
        setTimeout(() => {
          this.$router.push('/main/user/userinfo');
        }, 1000);
      }
    },
    hideHead() {
      this.showHead = false;
    },
    hidePassword() {
      this.showPassword = false;
      this.oldpassword = '';
      this.password = '';
      this.repassword = '';
    },
    hideSetPaypwd() {
      this.showSetPaypwd = false;
      this.oldpaypwd = '';
      this.paypwd = '';
      this.repaypwd = '';
    },
    hideRePaypwd() {
      this.showRePaypwd = false;
      this.oldpaypwd = '';
      this.paypwd = '';
      this.repaypwd = '';
    },
    //修改资金密码
    resetPaypwd(e) {
      const { oldpaypwd, paypwd, repaypwd } = this;
      let field = {
        oldpaypwd,
        paypwd,
        repaypwd,
      };
      //校验表单
      var objSome = Object.getOwnPropertyNames(field).some((key) => {
        let validInfo = this.$validate['valid' + key.charAt(0).toUpperCase() + key.slice(1)](field[key], field);
        if (!validInfo.status) {
          this.$toast({
            message: this.$t(validInfo.msg),
            duration: 1000,
          });
          return true;
        }
      });
      if (!objSome) {
        rePaypwd({ oldpaypwd, paypwd, repaypwd })
          .then((response) => {
            this.$toast({
              message: response.msg,
              duration: 1000,
              forbidClick: true,
            });
            setTimeout(() => {
              this.hideRePaypwd();
            }, 1000);
          })
          .catch((error) => {});
      }
    },
    //设置资金密码
    setPayPwdHandle(e) {
      const { paypwd, repaypwd } = this;
      let field = {
        paypwd,
        repaypwd,
      };
      //校验表单
      var objSome = Object.getOwnPropertyNames(field).some((key) => {
        let validInfo = this.$validate['valid' + key.charAt(0).toUpperCase() + key.slice(1)](field[key], field);
        if (!validInfo.status) {
          this.$toast({
            message: this.$t(validInfo.msg),
            duration: 1000,
          });
          return true;
        }
      });
      if (!objSome) {
        const { paypwd, repaypwd } = this;
        setPaypwd({
          paypwd,
          repaypwd,
        })
          .then((response) => {
            this.$toast({
              message: response.msg,
              duration: 1000,
              forbidClick: true,
            });
            setTimeout(() => {
              this.is_set_paypwd = 1;
              this.hideSetPaypwd();
            }, 1000);
          })
          .catch((error) => {});
      }
    },
    //修改登录密码
    resetPassWord(e) {
      const { oldpassword, password, repassword } = this;
      let field = {
        oldpassword,
        password,
        repassword,
      };
      //校验表单
      var objSome = Object.getOwnPropertyNames(field).some((key) => {
        let validInfo = this.$validate['valid' + key.charAt(0).toUpperCase() + key.slice(1)](field[key], field);
        if (!validInfo.status) {
          this.$toast({
            message: this.$t(validInfo.msg),
            duration: 1000,
          });
          return true;
        }
      });
      if (!objSome) {
        const { oldpassword, password, repassword } = this;
        rePassword({ oldpassword, password, repassword })
          .then((response) => {
            this.$toast({
              message: response.msg,
              duration: 1000,
              forbidClick: true,
            });
            setTimeout(() => {
              this.hidePassword();
            }, 1000);
          })
          .catch((error) => {});
      }
    },
    onAvatar(value) {
      setAvatar({ number: value })
        .then((response) => {
          this.$toast({
            message: response.msg,
            duration: 500,
            forbidClick: true,
          });
          setTimeout(() => {
            this.showHead = false;
            this.avatar = require('@/assets/images/head_' + value + '.png');
          }, 500);
        })
        .catch((error) => {});
    },
    onClear() {
      logout({})
        .then((response) => {
          this.$toast({
            message: response.msg,
            duration: 500,
            forbidClick: true,
          });
          setTimeout(() => {
            saveToLocal('token', '');
            saveToLocal('mobile', '');
            saveToLocal('password', '');
            this.$router.replace('/login');
          }, 500);
        })
        .catch((error) => {});
    },
    onClickLeft() {
      this.$router.push('/main/user');
    },
  },
};
</script>
<style lang="stylus">
.settingView
  .van-cell
    background-color: #151d31
    align-items: center
    margin-bottom: 0.03rem
    .icon
      width: 0.7rem
      height: 0.7rem
      margin-right: 0.2rem
      img
        width: 100%
        height: 100%
  .marginTop
    margin-top: 0.5rem
  .active
    padding: 0 0.5rem
    .btn
      margin-top: 0.5rem
  .showHead
    .van-grid
      .icon
        width: 1.1rem
        height: 1.1rem
        img
          width: 100%
          height: 100%
  .showPassword
    .van-cell-group
      margin-top: 0.3rem
      .van-field__label
        line-height: 0.36rem
    .btn
      width: 95%
      margin: 0.5rem auto
  .van-cell__left-icon
    background-color: #444
    border-radius: 100%
    text-align: center
    width: 0.75rem
    height: 0.75rem
    font-size: 0.46rem
    line-height: 0.75rem
</style>
